.row {
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0 0 0;
  flex-grow: 1;
  .input-box {
    display: flex;
    flex-grow: 1;

    position: relative;
    select {
      height: 48px;
      transition: 0.1s;

      padding: 10px;
      flex-grow: 1;

      color: #fff;
      background-color: #4f6877;
      display: block;
      padding: 5px 20px;
      min-height: 25px;
      line-height: 24px;
      overflow: hidden;
      border: 0;
      border-radius: 15px;
      letter-spacing: 1.5px;
      font-size: 12px;
      border-right: 16px solid transparent;
      &:hover {
        cursor: pointer;
      }
      &::placeholder {
        color: #fff;
      }
      &:focus::placeholder {
        color: transparent;
      }
      &:focus {
        outline: none;
      }
      > option {
        text-transform: uppercase;
        color: #fff;
        padding: 5px 20px;
        min-height: 25px;
        line-height: 24px;
        border: 0;
        border-radius: 15px;
        letter-spacing: 1.5px;
        font-size: 15px;
      }
    }
    span {
      position: absolute;
      transform: translateY(-4px);
      margin-left: 10px;
      padding: 19px;
      pointer-events: none;
      font-size: 12px;
      color: #fff;
      text-transform: uppercase;
      transition: 0.5s;
      letter-spacing: 3px;
    }

    select:focus ~ span,
    select:not(:placeholder-shown) ~ span {
      transform: translateX(7px) translateY(-20px);
      font-size: 10px;
      font-weight: 500;
      padding: 5px 10px;
      color: #6bbe92;
      letter-spacing: 0.2em;
      border: 2px;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4f6877;
    width: 38px;
    padding: 5px;
    margin-right: 3px;
    height: 38px;
    border-radius: 50%;
    color: #6bbe92;
  }
}
