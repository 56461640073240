.default-page {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  background-color: #374954;
  color: #cecece;

  font-size: calc(10px + 1vmin);
}