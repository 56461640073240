.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #374954;
    margin: 50px auto;
    padding: 20px;
    border-radius: 25px 0 25px 0;
    width: 80%;
    .close {
      color: #fa8072;
      font-size: 28px;
      align-self: flex-end;
      transition: 0.1s;
    }

    .close:hover,
    .close:focus {
      transition: 0.1s;
      color: red;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
