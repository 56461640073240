.logout {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 1rem;
  span {
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 5px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
