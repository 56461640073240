.form-task {
  display: flex;
  flex-direction: column;
  background-color: #374954;
  textarea,
  input {
    padding: 10px;
    margin-bottom: 10px;
  }

  input[type="submit"]:hover {
    cursor: pointer;
  }

  .raw {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
    .column {
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      &:first-child {
        margin-right: 1rem;
      }
    }
    > .row {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0px;
      }
    }
    img {
      max-width: 360px;
      float: left;
    }
  }
}
