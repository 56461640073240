.submit-btn {
  background-color: #6bbe92;
  height: 48px;
  border: 0;
  padding: 10px 20px;
  margin: 20px 0 0 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
