.collection-number {
  text-align: end;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}
.tasks-list {
  list-style: none;
  padding: 0;
}
