.header {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 10px 0;
  background-color: #374954;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    span {
      font-size: 2rem;
      margin: 5px 0;
      line-height: 1;
      font-weight: 500;

      color: #6bbe92;
    }
  }
  .typing {
    display: flex;
    justify-content: center;

    .typing-effect {
      width: 145px;
      animation: typing 1.5s steps(14) 1s forwards;
      white-space: nowrap;
      overflow: hidden;

      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 13px;
      font-family: revert;

      color: transparent;
    }
  }
}

@keyframes typing {
  from {
    width: 0px;
    color: white;
  }
  100% {
    color: white;
  }
}
