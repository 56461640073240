.auth-page {
  margin-top: 150px;
  padding: 40px 20px 0 20px;
  max-width: 400px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .auth-page {
    margin: 70px 10px;
    padding: 0;
    width: auto;
  }
}
