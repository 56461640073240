.task-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #4f6877;
  border-radius: 0 0 15px 15px;
  border-top: 4px solid #6bbe92;

  margin-bottom: 20px;
  .block-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    margin-bottom: 5px;
    span {
      display: flex;
      align-items: center;
      color: #ffd700;
      font-size: 18px;
      font-weight: 500;
      span {
        margin-left: 35px;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 3px;
        color: white;
        span {
          color: #fa6e6d;
          font-size: 18px;
          margin-left: 3px;
        }
      }
    }
    button {
      background-color: #4f6877;
      align-self: end;
      margin: 3px;
      font-size: 20px;
      color: #6bbe92;
      border: none;
      cursor: pointer;
    }
    .is-exported {
      color: #fa6e6d;
    }
  }
  > div {
    display: flex;
    flex-wrap: nowrap;

    text-align: left;
    span {
      color: white;
      flex: 1;
      display: flex;
      flex-grow: 1;
      font-size: 18px;
      padding: 0px 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    .block-img {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        box-shadow: 10px 5px 5px #374954;
      }
      .imgHd-block {
        position: fixed;
        z-index: 3;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: scroll;
        overflow-y: auto;
        background-color: rgb(0 0 0 / 80%);
        .imgHd {
          width: auto;
          height: auto;
          cursor: pointer;
        }
      }
    }
    .infos {
      display: flex;
      flex-direction: column;

      padding: 5px;
      margin: 0 0 0 10px;
      > span {
        display: flex;
        input {
          margin-right: 10px;
        }
      }
    }
  }
  > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    white-space: pre-wrap;
    padding: 5px;
  }
}

.modal-delete {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #374954;
    margin: 50px auto;
    padding: 20px;
    border-radius: 25px 0 25px 0;
    border: 2px solid #fa8072;
    .close {
      color: #fa8072;
      font-size: 28px;
      align-self: flex-end;
      transition: 0.1s;
      margin-bottom: 10px;
    }
    .close:hover,
    .close:focus {
      transition: 0.1s;
      color: red;
      text-decoration: none;
      cursor: pointer;
    }
    div {
      .input-block {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        input {
          cursor: pointer;
          padding: 15px;
          min-width: 130px;
          background-color: #fa8072;
          border: 1px solid #fa8072;
          font-weight: 700;
          color: #374954;
          border-radius: 5px;
          letter-spacing: 2px;
          &:first-child {
            margin-right: 50px;
            background-color: #374954;
            border: 1px solid #6bbe92;
            font-weight: 700;
            color: #6bbe92;
          }
        }
      }
    }
  }
}
