.tasks-manager {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .raw {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    margin-top: 1rem;
    label {
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 1rem;
      align-self: center;
      font-weight: 500;
    }
    input[type="checkbox"] {
      margin: 0px 20px 0 5px;
      align-self: center;
    }
    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &:first-child {
        margin-right: 1rem;
      }
    }
    > .row {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0px;
      }
    }
    .block-csv {
      margin-right: 5px;
      button {
        margin-left: 5px;
      }
    }
  }
  input {
    padding: 10px;
  }
  input:first-child {
    width: 100%;
  }
  button:hover,
  input[type="checkbox"] {
    cursor: pointer;
  }
  .collapse {
    color: #6bbe92;
    height: 20px;
    letter-spacing: 2px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1px solid #6bbe92;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #374954;
      padding: 0 10px;
      height: 40px;
      .icon {
        font-size: 1.5rem;
        transition: 0.5s;
        margin-left: 0.5rem;
      }
      .reverse {
        transition: 0.5s;
        transform: rotate(180deg);
      }
    }
  }
}
/*
.raw:last-child {
  margin-top: 10px;
  align-self: self-end;
}
*/
