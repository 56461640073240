.block-print-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin-bottom: 15px;
  .label {
    font-size: 0.75rem;
    letter-spacing: 2px;
    flex-grow: 0;
    margin-bottom: 2px;

    text-transform: uppercase;
    color: #6bbe92;
  }
  .value {
    font-size: 1rem;
  }
}
